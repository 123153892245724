import React from "react";
import Layout from "./components/Layout"


const App = () => {
  return(
    <Layout/>
  );
};

export default App;